.Home {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  bottom: 0;
  height: 100vh;
  left: 0;
}

.HomeSlider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.HomeSlider div {
  height: 100%;
}

.HomeSlide {
  background-size: cover;
  background-position: center center;
  height: 100vh;
}