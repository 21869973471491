.ButtonSwitch {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  font-size: inherit;
  border-bottom: 1px solid black;
  padding: 0 0 3px 0;
  margin-bottom: 30px;
}