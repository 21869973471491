.Nav {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 600px) {
  .Nav {
    width: 300px;
  }
}

.NavList {
  list-style: none;
  margin: 105px 0 0 0;
}

.NavItem {
  margin-bottom: 1.2em;
}

.NavLink {
  text-decoration: none;
  color: white;
  font-size: 3.0rem;
}

.NavLink:hover,
.NavLinkActive {
  color: #aaa;
}