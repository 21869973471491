
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  margin: 0;
}

@media only screen and (min-width: 600px) {
  body {
    font-size: 2.0rem;
  }
}

figure {
  margin: 0;
}

img {
  vertical-align: top;
}

button {
  cursor: pointer;
  border: none;
  background: none;
}

button:focus {
  outline: none;
}

p {
  margin: 0;
}

#root .section .section-container a {
  color: #9f9f9f;
  text-decoration: underline;
}

#root .section .section-container table a {
  color: inherit;
  text-decoration: none;
}

#root .section .section-container table a {
  color: inherit;
  text-decoration: none;
}

#root .section .section-container table a:hover {
  color: #888888;
}

small {
  font-size: 1.3rem;
}

h3 {
  font-weight: normal;
  margin: 0 0 1.2em;
  font-size: 2.0rem;
}

@media only screen and (min-width: 600px) {
  h3 {
    font-size: 2.8rem;
  }  
}

/* UTILITIES */

.img-fluid {
  width: 100%;
  height: auto;
}

.mb-base {
  margin-bottom: 50px;
}

.mb-small {
  margin-bottom: 40px;
}

.mb-smaller {
  margin-bottom: 30px;
}

@media only screen and (min-width: 600px) {
  .mb-base {
    margin-bottom: 105px;
  }

  .mb-small {
    margin-bottom: 80px;
  }
}

/* SECTION */

.section {
  position: absolute;
  top: 0;
  width: 100%;
  /*max-width: 1500px;*/
  z-index: -1;
  padding-bottom: 100px;
}

.section-container {
  max-width: 2500px;
  margin: 0 auto;
  /*margin-top: 40px;*/
  padding: 0;
}

@media only screen and (min-width: 600px) {
  .section-container {
    padding: 0 70px;
    margin-top: 40px;
  }
}

.section-headline {
  margin: 80px 0 35px 0;
  text-align: center;
  font-weight: normal;
  font-size: 2.2rem;
}

.section-headline-sub {
  margin: 0 0 35px 0;
  text-align: center;
  font-weight: normal;
  font-size: 2.2rem;
}

@media only screen and (min-width: 600px) {
  .section-headline {
    font-size: 4.5rem;
    margin: 150px 0 65px 0;
  }
}

/* PAGE TRANSITION */

.page-enter {
  opacity: 0.01;
}

.page-enter-active,
.page-enter-done {
  opacity: 1;
  -webkit-transition: all 300ms ease-in;
  transition: all 300ms ease-in;
}

.page-exit,
.page-exit-active {
  opacity: 0;
}

/* SLIDER */

.slider {
  height: 100% !important;
}

.slider .trach div {
  display: inline-block;
}

.slider a.previousButton:not(.disabled):hover, 
.slider a.nextButton:not(.disabled):hover {
  -webkit-transform: translateY(-50%) scale(1) !important;
          transform: translateY(-50%) scale(1) !important;
}

.slider .animateIn,
.slider .animateOut {
  -webkit-transition-duration: 1.0s !important;
          transition-duration: 1.0s !important;
}

.slick-slider {
  overflow: hidden;
}

/* MASONRY */

.masonry-grid-image {
  width: 70%;
  display: inline-block;
  text-decoration: none !important;
}

.masonry-grid-image img {
  width: 100%;
}

.masonry-grid-image.highlight {
  width: 100%;
}

.masonry-grid-sizer {
  width: 6.66%;
}

.masonry-grid-item {
  width: 100%;
  text-align: center;
  margin-bottom: 6.66%;
}

.masonry-grid-item a {
  text-decoration: none;
}

.masonry-grid-item a:hover span {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.masonry-grid-image,
.masonry-grid-image.highlight {
  width: 100%;
}

@media only screen and (min-width: 680px) {
  .masonry-grid-item {
    width: 46.66%;
    text-align: right;
    margin-bottom: 6.66%;
  }

  .masonry-grid-image {
    width: 70%;
  }
  
  .masonry-grid-image.highlight {
    width: 100%;
  }
}

/* HEADROOM */

.headroom--unpinned {
  -webkit-transition: none !important;
  transition: none !important;
}

/* */
.projekt .headroom-wrapper,
/*.projekt #logo,*/
.projekt #toggle,
.wettbewerb .headroom-wrapper,
/*.wettbewerb #logo,*/
.wettbewerb #toggle {
  display: none;
}

.projekt .logo-text,
.wettbewerb .logo-text {
  display: none;
}

#allprojectstable,
#allconteststable,
#publicationstable,
#awardstable,
#jurytable {
  display: none;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#root .section a {
  color: inherit;
  text-decoration: none;
}

#root .section a:hover {
  color: #888888;
}

.header-bar {
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  height: 45px;
}

@media only screen and (min-width: 600px) {
  .header-bar {
    background-color: transparent;
  } 
}


.container {
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.container-text {
  padding: 0 20px;
  width: 100%;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media only screen and (min-width: 600px) {
  .container {
    padding: 0 50px;
  }

  .container-text {
    padding: 0 0;
  }  

  .row {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .col {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

.projekte td:first-child {
  min-width: 125px;
}

.projekte td:last-child {
  min-width: 100px;
}

.table-vita table td:first-child {
  width: 170px;
}

#jurytable td:first-child,
#awardstable td:first-child,
#publicationstable td:first-child {
  width: 135px;
}

#jurytable td:last-child,
#awardstable td:last-child,
#publicationstable td:last-child {
  width: 495px;
}

.preloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader-icon {
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

.section#project {
  margin-top: 45px;
}

@media only screen and (min-width: 600px) {
  .section#project {
    margin-top: 0;
  }
}
.Home_Home__eGr-a {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  bottom: 0;
  height: 100vh;
  left: 0;
}

.Home_HomeSlider__239AF {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Home_HomeSlider__239AF div {
  height: 100%;
}

.Home_HomeSlide__Crz6X {
  background-size: cover;
  background-position: center center;
  height: 100vh;
}
.SliderArrows_SliderArrow__365GY {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  display: none;
}

@media only screen and (min-width: 600px) {
  .SliderArrows_SliderArrow__365GY {
    display: block;
  }
}

.SliderArrows_SliderArrow__365GY:focus {
  outline: none;
}

.SliderArrows_SliderArrowPrev__2BKHu {
  left: 12px;
}

.SliderArrows_SliderArrowNext__1jRKW {
  right: 12px;
}

@media only screen and (min-width: 600px) {
  .SliderArrows_SliderArrowPrev__2BKHu {
    left: 50px;
  }
  
  .SliderArrows_SliderArrowNext__1jRKW {
    right: 50px;
  }
}
.NeuesItem_NewsItemDescription__gvcta {
  margin-bottom: 30px;
}

@media only screen and (min-width: 768px) {
  .NeuesItem_NewsItemDescription__gvcta {
    margin-bottom: 0;
  }
}
.Masonry_MasonryTitleContainer__U2NFN {
  overflow: hidden;
  text-align: left;
  margin-top: 3px;
}

.Masonry_MasonryTitle__32ZGi {
  display: block;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  color: black;
  -webkit-transition: -webkit-transform 0.2s ease-in;
  transition: -webkit-transform 0.2s ease-in;
  transition: transform 0.2s ease-in;
  transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
  font-size: 1.6rem;
}

@media only screen and (min-width: 770px) {
  .Masonry_MasonryTitleContainer__U2NFN {
    margin-top: 20px;
  }

  .Masonry_MasonryTitle__32ZGi {
    font-size: 2.0rem;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}
.ButtonSwitch_ButtonSwitch__11iZv {
  display: inline-block;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin: 0;
  font-size: inherit;
  border-bottom: 1px solid black;
  padding: 0 0 3px 0;
  margin-bottom: 30px;
}
.Table_Table__JyGjG {
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  border-collapse: collapse;
  margin-bottom: 80px;
}

.Table_TableContainer__3rLXJ {
  overflow-x: auto;
}

.Table_TableCell__468WM {
  padding: 15px 0;
  border-top: 1px solid #dbdbdb;
  /*width: 150px;*/
  padding: 15px 30px 15px 0;
}

.Table_TableCell__468WM:last-child {
  width: auto;
  padding-right: 0;
}

.Table_TableCaption__1NBtn {
  padding: 0 0 15px 0;
  font-weight: bold;
  text-align: left;
}

.Table_TableHeadCell__3gQWH {
  text-align: left;
  font-weight: normal;
  color: #8b8b8b;
  padding: 15px 0;
  padding: 0 30px 15px 0;
}

.Table_TableHeadCell__3gQWH:last-child {
  padding-right: 0;
}
.Back_Back__1Azdi {
  position: fixed;
  top: 12px;
  right: 12px;
  display: block;
  z-index: 1000;
  width: 20px;
  height: 20px;
}

@media only screen and (min-width: 600px) {
  .Back_Back__1Azdi {
    right: 50px;
    top: 40px;
  }
}
.Detail_DetailGallery__2fRbo {
  position: relative;
}

.Detail_DetailGallery__2fRbo .Detail_slider__2nufg {
  height: 900px;
}

.Detail_DetailTitle__3-ROa {
  margin-bottom: 0;
}

.Detail_DetailTitleSpacing__35tEv {
  margin-bottom: 1.4em;
}

.Detail_DetailSubtitle__S_tPZ {
  margin-bottom: 1.4em;
}

.Detail_DetailFrameContainer__3ZYO2 {
  overflow: hidden;
}
.Buero_BueroIntro__3En0w {
  font-size: 2.0rem;
}

.Buero_BueroGalleryRight__3GA3w {
  padding-top: 0;
}

@media only screen and (min-width: 600px) {
  .Buero_BueroIntro__3En0w {
    font-size: 2.8rem;
  }

  .Buero_BueroGalleryRight__3GA3w {
    padding-top: 100px;
  }
}
.Kontakt_Map__5Yacg {
  width: 100% !important;
  height: 700px !important;
  position: relative !important;
}
.Logo_LogoSignet__1hESZ {
  width: 26px;
  display: inline-block;
  position: fixed;
  top: 10px;
  left: 12px;
  z-index: 1000;
}

@media only screen and (min-width: 600px) {
  .Logo_LogoSignet__1hESZ {
    left: 50px;
    top: 36px;
  }
}

.Logo_LogoText__yrXHl {
  width: 290px;
  margin: 40px 0 0 100px;
  display: none;
  height: 21px;
}

@media only screen and (min-width: 600px) {
  .Logo_LogoText__yrXHl {
    display: block;
    margin: 40px 0 0 100px;
  }
}

.Logo_LogoText__yrXHl svg,
.Logo_LogoSignet__1hESZ svg {
  width: 100%;
}
.Toggle_Toggle__1tyFB {
  border: none;
  background-color: transparent;
  padding: 0;
  position: fixed;
  right: 12px;
  top: 12px;
  z-index: 1000;
}

@media only screen and (min-width: 600px) {
  .Toggle_Toggle__1tyFB {
    right: 50px;
    top: 40px;
  }
}

.Toggle_Toggle__1tyFB:focus {
  outline: none;
}
.Nav_Nav__3QNgn {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 600px) {
  .Nav_Nav__3QNgn {
    width: 300px;
  }
}

.Nav_NavList__2cfIr {
  list-style: none;
  margin: 105px 0 0 0;
}

.Nav_NavItem__IoKaa {
  margin-bottom: 1.2em;
}

.Nav_NavLink__x5Kmo {
  text-decoration: none;
  color: white;
  font-size: 3.0rem;
}

.Nav_NavLink__x5Kmo:hover,
.Nav_NavLinkActive__1RI2H {
  color: #aaa;
}
