.Table {
  width: 100%;
  border-bottom: 1px solid #dbdbdb;
  border-collapse: collapse;
  margin-bottom: 80px;
}

.TableContainer {
  overflow-x: auto;
}

.TableCell {
  padding: 15px 0;
  border-top: 1px solid #dbdbdb;
  /*width: 150px;*/
  padding: 15px 30px 15px 0;
}

.TableCell:last-child {
  width: auto;
  padding-right: 0;
}

.TableCaption {
  padding: 0 0 15px 0;
  font-weight: bold;
  text-align: left;
}

.TableHeadCell {
  text-align: left;
  font-weight: normal;
  color: #8b8b8b;
  padding: 15px 0;
  padding: 0 30px 15px 0;
}

.TableHeadCell:last-child {
  padding-right: 0;
}