.SliderArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  display: none;
}

@media only screen and (min-width: 600px) {
  .SliderArrow {
    display: block;
  }
}

.SliderArrow:focus {
  outline: none;
}

.SliderArrowPrev {
  left: 12px;
}

.SliderArrowNext {
  right: 12px;
}

@media only screen and (min-width: 600px) {
  .SliderArrowPrev {
    left: 50px;
  }
  
  .SliderArrowNext {
    right: 50px;
  }
}