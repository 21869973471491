.MasonryTitleContainer {
  overflow: hidden;
  text-align: left;
  margin-top: 3px;
}

.MasonryTitle {
  display: block;
  transform: translateY(0);
  color: black;
  transition: transform 0.2s ease-in;
  font-size: 1.6rem;
}

@media only screen and (min-width: 770px) {
  .MasonryTitleContainer {
    margin-top: 20px;
  }

  .MasonryTitle {
    font-size: 2.0rem;
    transform: translateY(100%);
  }
}