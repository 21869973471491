.BueroIntro {
  font-size: 2.0rem;
}

.BueroGalleryRight {
  padding-top: 0;
}

@media only screen and (min-width: 600px) {
  .BueroIntro {
    font-size: 2.8rem;
  }

  .BueroGalleryRight {
    padding-top: 100px;
  }
}