.LogoSignet {
  width: 26px;
  display: inline-block;
  position: fixed;
  top: 10px;
  left: 12px;
  z-index: 1000;
}

@media only screen and (min-width: 600px) {
  .LogoSignet {
    left: 50px;
    top: 36px;
  }
}

.LogoText {
  width: 290px;
  margin: 40px 0 0 100px;
  display: none;
  height: 21px;
}

@media only screen and (min-width: 600px) {
  .LogoText {
    display: block;
    margin: 40px 0 0 100px;
  }
}

.LogoText svg,
.LogoSignet svg {
  width: 100%;
}