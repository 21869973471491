.Toggle {
  border: none;
  background-color: transparent;
  padding: 0;
  position: fixed;
  right: 12px;
  top: 12px;
  z-index: 1000;
}

@media only screen and (min-width: 600px) {
  .Toggle {
    right: 50px;
    top: 40px;
  }
}

.Toggle:focus {
  outline: none;
}