
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  margin: 0;
}

@media only screen and (min-width: 600px) {
  body {
    font-size: 2.0rem;
  }
}

figure {
  margin: 0;
}

img {
  vertical-align: top;
}

button {
  cursor: pointer;
  border: none;
  background: none;
}

button:focus {
  outline: none;
}

p {
  margin: 0;
}

#root .section .section-container a {
  color: #9f9f9f;
  text-decoration: underline;
}

#root .section .section-container table a {
  color: inherit;
  text-decoration: none;
}

#root .section .section-container table a {
  color: inherit;
  text-decoration: none;
}

#root .section .section-container table a:hover {
  color: #888888;
}

small {
  font-size: 1.3rem;
}

h3 {
  font-weight: normal;
  margin: 0 0 1.2em;
  font-size: 2.0rem;
}

@media only screen and (min-width: 600px) {
  h3 {
    font-size: 2.8rem;
  }  
}

/* UTILITIES */

.img-fluid {
  width: 100%;
  height: auto;
}

.mb-base {
  margin-bottom: 50px;
}

.mb-small {
  margin-bottom: 40px;
}

.mb-smaller {
  margin-bottom: 30px;
}

@media only screen and (min-width: 600px) {
  .mb-base {
    margin-bottom: 105px;
  }

  .mb-small {
    margin-bottom: 80px;
  }
}

/* SECTION */

.section {
  position: absolute;
  top: 0;
  width: 100%;
  /*max-width: 1500px;*/
  z-index: -1;
  padding-bottom: 100px;
}

.section-container {
  max-width: 2500px;
  margin: 0 auto;
  /*margin-top: 40px;*/
  padding: 0;
}

@media only screen and (min-width: 600px) {
  .section-container {
    padding: 0 70px;
    margin-top: 40px;
  }
}

.section-headline {
  margin: 80px 0 35px 0;
  text-align: center;
  font-weight: normal;
  font-size: 2.2rem;
}

.section-headline-sub {
  margin: 0 0 35px 0;
  text-align: center;
  font-weight: normal;
  font-size: 2.2rem;
}

@media only screen and (min-width: 600px) {
  .section-headline {
    font-size: 4.5rem;
    margin: 150px 0 65px 0;
  }
}

/* PAGE TRANSITION */

.page-enter {
  opacity: 0.01;
}

.page-enter-active,
.page-enter-done {
  opacity: 1;
  transition: all 300ms ease-in;
}

.page-exit,
.page-exit-active {
  opacity: 0;
}

/* SLIDER */

.slider {
  height: 100% !important;
}

.slider .trach div {
  display: inline-block;
}

.slider a.previousButton:not(.disabled):hover, 
.slider a.nextButton:not(.disabled):hover {
  transform: translateY(-50%) scale(1) !important;
}

.slider .animateIn,
.slider .animateOut {
  transition-duration: 1.0s !important;
}

.slick-slider {
  overflow: hidden;
}

/* MASONRY */

.masonry-grid-image {
  width: 70%;
  display: inline-block;
  text-decoration: none !important;
}

.masonry-grid-image img {
  width: 100%;
}

.masonry-grid-image.highlight {
  width: 100%;
}

.masonry-grid-sizer {
  width: 6.66%;
}

.masonry-grid-item {
  width: 100%;
  text-align: center;
  margin-bottom: 6.66%;
}

.masonry-grid-item a {
  text-decoration: none;
}

.masonry-grid-item a:hover span {
  transform: translateY(0);
}

.masonry-grid-image,
.masonry-grid-image.highlight {
  width: 100%;
}

@media only screen and (min-width: 680px) {
  .masonry-grid-item {
    width: 46.66%;
    text-align: right;
    margin-bottom: 6.66%;
  }

  .masonry-grid-image {
    width: 70%;
  }
  
  .masonry-grid-image.highlight {
    width: 100%;
  }
}

/* HEADROOM */

.headroom--unpinned {
  transition: none !important;
}

/* */
.projekt .headroom-wrapper,
/*.projekt #logo,*/
.projekt #toggle,
.wettbewerb .headroom-wrapper,
/*.wettbewerb #logo,*/
.wettbewerb #toggle {
  display: none;
}

.projekt .logo-text,
.wettbewerb .logo-text {
  display: none;
}

#allprojectstable,
#allconteststable,
#publicationstable,
#awardstable,
#jurytable {
  display: none;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#root .section a {
  color: inherit;
  text-decoration: none;
}

#root .section a:hover {
  color: #888888;
}

.header-bar {
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  height: 45px;
}

@media only screen and (min-width: 600px) {
  .header-bar {
    background-color: transparent;
  } 
}


.container {
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.container-text {
  padding: 0 20px;
  width: 100%;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media only screen and (min-width: 600px) {
  .container {
    padding: 0 50px;
  }

  .container-text {
    padding: 0 0;
  }  

  .row {
    margin-left: -50px !important;
    margin-right: -50px !important;
  }

  .col {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

.projekte td:first-child {
  min-width: 125px;
}

.projekte td:last-child {
  min-width: 100px;
}

.table-vita table td:first-child {
  width: 170px;
}

#jurytable td:first-child,
#awardstable td:first-child,
#publicationstable td:first-child {
  width: 135px;
}

#jurytable td:last-child,
#awardstable td:last-child,
#publicationstable td:last-child {
  width: 495px;
}

.preloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloader-icon {
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

.section#project {
  margin-top: 45px;
}

@media only screen and (min-width: 600px) {
  .section#project {
    margin-top: 0;
  }
}