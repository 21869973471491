.DetailGallery {
  position: relative;
}

.DetailGallery .slider {
  height: 900px;
}

.DetailTitle {
  margin-bottom: 0;
}

.DetailTitleSpacing {
  margin-bottom: 1.4em;
}

.DetailSubtitle {
  margin-bottom: 1.4em;
}

.DetailFrameContainer {
  overflow: hidden;
}